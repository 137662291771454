@import "bootstrap";

img { margin-bottom: 24px; 
     margin-top: 10px; 
    border-radius:2%;
}


body {
	padding-top: 70px;
}

figcaption {
    text-align: right;
    margin-top: -20px;
    margin-bottom: 20px;
}

a.anchor {
   color: #555555;
   padding-top: 75px; 
   margin-top: -75px;
}

/* Top navigation bar */


p.byline {
	text-align: center;
	font-size: 18px;
	line-height: 36px;
	margin-top: -24px;
	margin-bottom: 48px;
}


pubtit {
	font-weight: bold;
}

firstp {
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px; /* or 950px */
  font-family: 'Raleway', sans-serif;
  font-size: .95em;
}

/* Home, News */
#newsid p {
	text-align: left;
}

.well {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #bbb;
}

#homeidtmp h1 {
	text-align: center;
}

#newsid img {
	margin-top: 0px;
	margin-bottom: 6px;
}

/* Team & Publications */
#gridid img { margin: 16px 22px 6px 0; border-radius:10%; box-shadow: 2px 2px 5px #888888 }
#homeid img { margin: 0 0 0 0; border-radius:5%; box-shadow: 0px 0px 0px #888888 }
#picid img { margin: 6px 3px 60px 6px; border-radius:1%; box-shadow: 2px 2px 5px #888888 }
#gridid .row { margin-bottom: 24px; }





/* Carusel */

.carousel {
    border-radius: 3px 3px 3px 3px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

// Colors
$red-color:           #FF3636 !default;
$red-color-dark:      #B71C1C !default;
$orange-color:        #F29105 !default;
$blue-color:          #0076df !default;
$blue-color-dark:     #00369f !default;
$cyan-color:          #2698BA !default;
$green-color:         #00ab37 !default;
$green-color-lime:    #B7D12A !default;
$green-color-dark:    #009f06 !default;
$green-color-light:   #ddffdd !default;
$green-color-bright:  #11D68B !default;
$purple-color:        #B509AC !default;
$pink-color:          #f92080 !default;
$pink-color-light:    #ffdddd !default;
$yellow-color:        #efcc00 !default;

$grey-color:          #828282 !default;
$grey-color-light:    lighten($grey-color, 40%);
$grey-color-dark:     darken($grey-color, 25%);

/* Set theme colors *************************/
$theme-color:         $grey-color;

$link-color:          $theme-color;
$text-color:          #111 !default;

.badge {
  font-family: 'Dosis', sans-serif;
  font-weight: bolder;
  color: white;
  background-color: $grey-color;
  margin-top:1rem;
  padding: 3px 5.9px;
}

.sideimage{
  max-width: 70%;
  box-shadow: 0 3px 10px rgba(0,0,0,0.2);
}

.sideimage_placeholder{
  max-width: 70%;
}

@media (max-width:629px) {
  img#optionalstuff {
    display: none;
  }
}

.publications {
  margin-top: 1rem;
  h2.year {
    color: $grey-color-light;
    border-top: 1px solid $grey-color-light;
    padding-top: 1rem;
    margin-top: 2rem;
    margin-bottom: -0.5rem;
    text-align: right;
  }
  ol.bibliography {
    list-style: none;
    padding: 0;
    margin-top: -0.5rem;

    li {
      margin-bottom: 1rem;
      .abbr {
        height: 2rem;
        margin-bottom: 0.5rem;
        abbr {
          display: inline-block;
          background-color: $theme-color;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      span {
        display: block;
      }
      .abs {
        padding: 8px 15px;
        font-size: 0.875em;
        border: dashed 1px white;
        border-color: $grey-color;
        text-align: justify;
        line-height: 1.4em;
        display: none;
        font-family: "Open Sans", sans-serif;
        word-wrap: break-all;
      }
      .abs.open {
        max-height: 100em;
        -webkit-transition: 0.05s ease;
        -moz-transition: 0.05s ease;
        -ms-transition: 0.05s ease;
        -o-transition: 0.05s ease;
        transition: all 0.05s ease;
      }
      .bibtex {
        border: dashed 1px white;
        border-color: $grey-color;
        background-color: white;
        display: none;
      }
      .bibtex pre {
        border-style: none;
        background-color: none;
        font-size: 0.875em;
        font-family: "Open Sans", sans-serif;
        background-color: white;
      }
      .copy {
        font-size: 0.85em;
        font-family: "Open Sans", sans-serif;
        display:inline-block;
        float: right;
        padding:0.35em 1.2em;
        border:0.5em solid #FFFFFF;
        margin:0 0.3em 0.3em 0;
        border-radius:0.7em;
        box-sizing: border-box;
        text-decoration:none;
        font-weight:300;
        color:#000000;
        text-align:center;
        transition: all 0.2s;
      }
      .copy:hover{
      color:#495867;
      background-color:#FFFFFF;
      }
      .title {
        font-weight: bolder;
      }
      .author {
        a {
          border-bottom: 1px dashed $theme-color;
          &:hover {
              border-bottom-style: solid;
              text-decoration: none;
          }
        }
        > em {
          border-bottom: 1px solid;
          font-style: normal;
        }
      }
      em.star {
        border-bottom: none;
        color: $theme-color;
        font-style: normal;
      }
      a.abstract, a.bibtex {
        cursor: pointer;
      }
      .hidden {
        font-size: 0.875rem;
        max-height: 0px;
        overflow: hidden;
        text-align: justify;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;

        p {
          line-height: 1.4em;
          margin: 10px;
        }
        pre {
          font-size: 0.8em;
          line-height: 1.4em;
          padding: 10px;
        }
      }
      .hidden.open {
        max-height: 100em;
        -webkit-transition: 0.15s ease;
        -moz-transition: 0.15s ease;
        -ms-transition: 0.15s ease;
        -o-transition: 0.15s ease;
        transition: all 0.15s ease;
      }
      div.abstract.hidden {
        border: dashed 1px white;
      }
      div.abstract.hidden.open {
        border-color: $grey-color;
      }
    }
  }
}